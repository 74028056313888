export function average(records: number[]) {
  return Math.ceil(records.reduce((sum, c) => sum + c, 0) / records.length)
}

export function rotationRandom(banners: any[]) {
  if (!banners?.length) return null

  const slots = []
  for (const banner of banners) {
    for (let i = 0; i < banner.rotation; i++) {
      slots.push(banner)
    }
  }
  const index = Math.floor(Math.random() * slots.length)
  return slots[index]
}
