import Select from 'react-dropdown-select'
import { useQuery } from 'react-query'
import { getAfreecaCount } from '@/api/poongtoday'
import { useRef } from 'react'

export default function CategorySelector({
  categories,
  selectedCategories,
  setSelectedCategories,
}: {
  categories: any
  selectedCategories: { key: string; value: string }[]
  setSelectedCategories: any
}) {
  const cates = useRef<{ key: string; value: string }[]>([])

  useQuery(['afreeaCount'], () => getAfreecaCount(), {
    onSuccess: (data: any) => {
      cates.current = []
      if (!data?.l) return

      data.l
        .sort((cate1: any, cate2: any) => cate2.b - cate1.b)
        .map((category: any) => {
          cates.current.push({
            key: String(category.m * 10000 + category.s).padStart(8, '0'),
            value: categories[String(category.m * 10000 + category.s).padStart(8, '0')],
          })
        })
    },
  })

  return (
    <div className="select-box">
      <div className="select-area">
        <Select
          options={cates.current}
          values={selectedCategories}
          labelField="value"
          valueField="key"
          searchBy="value"
          onChange={(values: { key: string; value: string }[]) => setSelectedCategories(values)}
          multi={true}
          dropdownPosition="bottom"
          clearable={true}
          searchable={true}
          placeholder="카테고리 선택"
          addPlaceholder="검색"
        />
      </div>
    </div>
  )
}
