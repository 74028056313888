import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import * as Sentry from '@sentry/react'
import 'react-quill/dist/quill.core.css'
import 'react-quill/dist/quill.snow.css'
import 'react-datepicker/dist/react-datepicker.css'
import './index.scss'
import Guide from './routes/guide.tsx'
import Layout from './_layout.tsx'
import Main from './routes/main.tsx'
import Platform from './routes/platform.tsx'
import Coupons from './routes/mypage/coupons.tsx'
import Pointshop from './routes/pointshop.tsx'

import BroadcastLive from './routes/rankings/broadcast/live'
import Broadcast from './routes/rankings/broadcast/broadcast.tsx'
import BroadcastHistory from './routes/rankings/broadcast/history.tsx'

import BroadcastView from './routes/broadcastDetail.tsx'

import BigDonor from './routes/rankings/donor.tsx'
import BigDonorHistory from './routes/rankings/donorHistory.tsx'

import Boards from './routes/community/boards.tsx'
import Announcement from './routes/community/announcement.tsx'
import Board from './routes/community/board.tsx'
import EditBoard from './routes/community/editBoard.tsx'
import AddBoard from './routes/community/addBoard.tsx'
import MyBoard from './routes/community/myboard.tsx'
import MyComment from './routes/community/mycomment.tsx'

import SearchResults from './routes/search/results.tsx'

import Mypage from './routes/mypage/mypage.tsx'
import ChangeNickname from './routes/mypage/changeNickname.tsx'
import PointDetails from './routes/mypage/pointDetails.tsx'
import Inquiries from './routes/mypage/inquiries/index.tsx'
import AddInquiry from './routes/mypage/inquiries/addInquiry.tsx'
import Inquiry from './routes/mypage/inquiries/inquiry.tsx'

import CallbackOauthKakao from './routes/callback/oauth/kakao.tsx'
import CallbackOauthGoogle from './routes/callback/oauth/google.tsx'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Main />,
      },
      {
        path: '*',
        element: <Main />,
      },
      {
        path: '/guide',
        element: <Guide />,
      },
      {
        path: '/platform',
        element: <Platform />,
      },
      {
        path: '/pointshop',
        element: <Pointshop />,
      },
      {
        path: '/rankings',
        children: [
          {
            path: '/rankings/broadcast',
            element: <Broadcast />,
          },
          {
            path: '/rankings/broadcast/live',
            element: <BroadcastLive />,
          },
          {
            path: '/rankings/broadcast/history',
            element: <BroadcastHistory />,
          },
          {
            path: '/rankings/donor',
            element: <BigDonor />,
          },
          {
            path: '/rankings/donor/history',
            element: <BigDonorHistory />,
          },
        ],
      },
      {
        path: '/broadcast',
        children: [
          {
            path: '/broadcast/:id',
            element: <BroadcastView />,
          },
        ],
      },
      {
        path: '/community',
        children: [
          {
            path: '/community/announcement/:id',
            element: <Announcement />,
          },
          {
            path: '/community/boards',
            element: <Boards />,
          },
          {
            path: '/community/boards/add',
            element: <AddBoard />,
          },
          {
            path: '/community/boards/view/:id',
            element: <Board />,
          },
          {
            path: '/community/boards/edit/:id',
            element: <EditBoard />,
          },
          {
            path: '/community/my',
            children: [
              {
                path: '/community/my/boards',
                element: <MyBoard />,
              },
              {
                path: '/community/my/comments',
                element: <MyComment />,
              },
            ],
          },
        ],
      },
      {
        path: '/search',
        children: [
          {
            path: '/search/results',
            element: <SearchResults />,
          },
        ],
      },
      {
        path: '/callback/oauth/kakao',
        element: <CallbackOauthKakao />,
      },
      {
        path: '/callback/oauth/google',
        element: <CallbackOauthGoogle />,
      },
      {
        path: '/my',
        children: [
          {
            path: '/my/mypage',
            element: <Mypage />,
          },
          {
            path: '/my/changeNickname',
            element: <ChangeNickname />,
          },
          {
            path: '/my/points',
            element: <PointDetails />,
          },
          {
            path: '/my/coupons',
            element: <Coupons />,
          },
          {
            path: '/my/inquiries',
            element: <Inquiries />,
          },
          {
            path: '/my/inquiries/add',
            element: <AddInquiry />,
          },
          {
            path: '/my/inquiries/view/:id',
            element: <Inquiry />,
          },
        ],
      },
    ],
  },
])

const sentryDsn = import.meta.env.VITE_SENTRY_DSN
if (sentryDsn) {
  Sentry.init({
    dsn: 'https://8bf1ee9567113b5e66fff05422db2bb2@o565406.ingest.us.sentry.io/4507321411108864',
    ignoreErrors: ['AxiosError', 'Network Error', 'Nc(gtag/js)', 'gtag', 'Failed to fetch', 'zrender'],
  })
}
const queryClient = new QueryClient()

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>,
)
